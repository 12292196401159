.fading-text-container {
    position: relative;
    width: 100%;
    height: 2rem; /* You can adjust this to fit your layout */
    display: flex;
  }
  
  .fade-text {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;
    white-space: nowrap; /* Prevent text from wrapping */
  }
  
  .fade-text.visible {
    opacity: 1;
  }
  